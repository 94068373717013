import React, {Component} from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Paper from 'material-ui/Paper';
import './Screen.css';

let _this;

class Slide extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }

    componentDidMount() {}

    render() {
        return (
            <MuiThemeProvider>
                <div className="placeholder">
                    <Paper className="paper_photo" zDepth={2}>
                        <div className="photo_center">
                            {this.props.imageURL ? (
                                <img className="photo" src={this.props.imageURL} alt="loading..."/>
                            ) : (
                                <h1 className="wait_text">Waiting for a Photo...</h1>
                            )}
                            <a className="close_button_shadow" ><h2 className="fine">Close</h2></a>
                            <a className="close_button" ><h2 className="fine" onClick={() => _this.props.onCloseButton()}>Close</h2></a>
                        </div>

                    </Paper>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default Slide;