import React, {Component} from 'react';
import './SessionQRCode.css';
import './Screen.css';

class SessionQRCode extends Component {
    constructor(props, context) {
        super(props, context);
        window.sid = null;
        this.state = {
            objectURL: null
        };
    }

    componentDidMount() {
        let _this = this;
        let path = '/api/get_qr_code';
        // alert(process.env.NODE_ENV)
        const env = process.env.NODE_ENV;
        if (env === 'development') {
            path = '/get_qr_code';
        }

        fetch(path, {
            method: "GET",
            headers: {
                'Cache': 'no-cache'
            },
            credentials: 'include'
        }).then(function (response) {
            //keep session id in window
            window.sid = response.headers.get('session-number');
            return response.text();
        }).then(function (text) {
            // console.log("getqr=>" + text);
            _this.props.gotSessionId();
            _this.setState({
                objectURL: text
            });
        });
    }

    render() {
        if (!this.state.objectURL) {
            return <div><h3>loading...</h3></div>
        }
        return <img src={this.state.objectURL} alt="loading..."/>
    }
}

export default SessionQRCode;