import React, {Component} from 'react';
import './TermsPolicy.css';

let _this;

class PrivacyPolicy extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        // setCurrentScreen(analytics, window.location.pathname);
        // logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <div className="top_menu containerNoMargin">
                <ul>
                    <li>
                        <a href="/" role="button"
                           onClick={() => _this.props.navigate('Home')}> Home </a>
                    </li>
                    <li>
                        <a href="/#help" role="button"
                           onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                    </li>
                    <li>
                        <a href="/#terms" role="button"
                           onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                    </li>
                    <li className="first">
                        <a href="/#policy" role="button"
                           onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                    </li>
                    <li>
                        <a href="/#about" role="button"
                           onClick={() => _this.props.navigate('About')}> Support </a>
                    </li>
                    <li>
                        <a href="#mobile" role="button"
                           onClick={() => _this.props.navigate('Mobile')}> Mobile </a>
                    </li>
                </ul>
            </div>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>PhotoDj: In-Person Slideshow - Privacy Policy
                        </h2>
                    </p>
                    Last updated: November 22, 2023
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        PhotoDj: In-Person Slideshow Apps and Services, which are wholly owned and controlled by
                        AnswerSolutions LLC
                        (“PhotoDj: In-Person Slideshow” “we,” “our,” and/or “us”), value the privacy of individuals who
                        use our
                        website, apps, and related services (collectively, our “Services”). This privacy policy (the
                        “Privacy Policy”) explains how we collect, use, and share information from or about PhotoDj
                        users (“Users”) or their devices. Moreover, it explains the rights and choices
                        available to users concerning their personal information.
                    </p>
                    <p>
                        By using our Services, you agree to the collection, use, disclosure, and procedures this Privacy
                        Policy describes. Beyond the Privacy Policy, your use of our Services is also subject to our
                        Terms of Service.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">1. INFORMATION WE COLLECT AND STORE</h3>
                        We may collect a variety of information from or about you or your devices from various sources,
                        as described below
                    </p>
                    <p>
                        If you do not provide your information when requested, you may not be able to use our Services
                        if that information is necessary to provide you with our Services or if we are legally required
                        to collect it.
                    </p>
                    <p>
                        <h3 className="policy">A. Information You Provide to Us.</h3>
                    </p>
                    <p>
                        <h3 className="policy">Registration.</h3>
                        PhotoDj: In-Person Slideshow app requires a phone number to sign-up for the service. We do not
                        collect email
                        addresses, Advertising IDs, or IDFA. The service does not require creating a username, and we
                        don’t ask for credit card information!
                    </p>
                    <p>
                        <h3 className="policy">Communications.</h3>
                        If you contact us directly, we may receive additional information about you. For example, when
                        you contact our Customer Support, we will receive your name, email address, the contents of a
                        message or attachments you may send us, and other information you choose to provide. When we
                        send you emails, we may track whether you open them to learn how to deliver a better customer
                        experience and improve our Services.
                    </p>
                    <p>
                        <h3 className="policy">B. Information We Collect When You Use Our Services.</h3>
                    </p>
                    <p>
                        <h3 className="policy">Location Information.</h3>
                        We do not collect your location information!
                    </p>
                    <p>
                        <h3 className="policy">Device Information.</h3>
                        We receive information about the device and software you use to access our Services, including
                        internet protocol (IP) address, phone carrier and manufacturer, device identifiers, and push
                        notification tokens. Device language (for example, English), Operating system used (for example,
                        iOS 13, Android 11), type of internet (WiFi/5G), signal strength, hardware and software
                        information, device type (for example, iPhone), Time zone, Screen resolution.
                    </p>
                    <p>
                        <h3 className="policy">Usage Information. </h3>
                        To help us understand how you use our Services and to help us improve them, we automatically
                        receive information about your interactions with our Services, like the pages, screens, or other
                        content you view or touch, interactions, and actions.
                    </p>
                    <p>
                        <h3 className="policy">Information from Cookies and Similar Technologies.</h3>
                        Third-party partners and we collect information using cookies or similar technologies. Our
                        third-party partners, such as analytics partners, may use these technologies to collect
                        information about your online activities over time and across different services. Cookies are
                        small text files containing a string of alphanumeric characters. We may use both session cookies
                        and persistent cookies. A session cookie disappears after you close your browser. A persistent
                        cookie remains after you close your browser and may be used by your browser on subsequent visits
                        to our Services.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">2. HOW WE USE THE INFORMATION WE COLLECT</h3>
                        We use the information we collect:
                        <ul>
                            <li>To provide, maintain, improve, and enhance our Services, verifying user information and
                                access.
                            </li>
                            <li>To provide you with a secure experience and to take measures to protect our website and
                                mobile applications from cyber risks.
                            </li>
                            <li>Protecting against, identifying, investigating, preventing, and responding to fraud,
                                illegal activity (such as incidents of hacking or misuse of our websites and mobile
                                applications), and claims and other liabilities, including by enforcing the terms and
                                conditions that govern the Services we provide.
                            </li>
                            <li>Complying with legal or regulatory requirements, judicial process, industry standards,
                                and our company policies.
                            </li>
                            <li>Other purposes may be described when you choose to provide personal information to us.
                            </li>
                            <li>To communicate with you, provide you with updates and other information relating to our
                                Services, provide information that you request, respond to comments and questions, and
                                otherwise provide customer support.
                            </li>
                            <li>Measuring usage of our websites and mobile applications.</li>
                            <li>To send you text messages and push notifications, where applicable.</li>
                            <li>To facilitate transactions.</li>
                            <li>To find and prevent fraud and respond to possible trust and safety issues.</li>
                            <li>For compliance purposes, including enforcing our Terms of Service or other legal rights,
                                or as may be required by applicable laws and regulations or requested by any judicial
                                process or governmental agency and for other purposes for which, we provide specific
                                notice at the time the information is collected.
                            </li>
                            <li>Debugging/Repairing any uses with the apps and Services.</li>
                        </ul>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">3. HOW DO WE SHARE THE INFORMATION WE COLLECT</h3>
                    </p>
                    <p>
                        <h3 className="policy">Vendors and Service Providers.</h3>
                    </p>
                    <p>
                        <h3 className="policy">Technology providers necessary to provide our services.</h3>
                        This includes cloud storage and web hosting providers. We store user-provided and Automated
                        Information and/or aggregate or non-personally identifiable information with our cloud storage
                        providers. We also may make certain Automated Information available for various purposes, such
                        as monitoring network traffic to detect malicious actors and to protect against malware, fraud,
                        or other unlawful uses or activity. Additionally, we may use a third-party provider to assist us
                        in providing transcription services.
                    </p>
                    <p>
                        <h3 className="policy">Payment processors.</h3>
                        Currently, you can purchase our Services from the Apple or Google stores only!
                    </p>
                    <p>
                        <h3 className="policy">Legal, Regulatory, Compliance, or Similar.</h3>
                        In addition, we may disclose and/or share your information to comply with legal or regulatory
                        requirements (including to comply with a court order, judicial subpoena or other subpoena or
                        warrant), industry standards, judicial process, and our company policies, as well as to protect
                        against, identify, investigate, prevent and respond to fraud, illegal activity (such as
                        identifying and responding to incidents of hacking or misuse of our websites and mobile
                        applications), adverse event reporting, and claims and other liabilities.
                    </p>
                    <p>
                        We also reserve the right to disclose your information when we believe in good faith that
                        disclosure is appropriate or necessary to take precautions against liability, to protect
                        our rights or property or the legal and property rights of others, and investigate and
                        defend third party claims or allegations against us.
                    </p>
                    <p>
                        In addition, we may collect, use, and disclose your personal information as required or
                        permitted by applicable law or as directed by you per this Privacy Policy.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">4. SECURITY</h3>
                        We make reasonable efforts to protect your information by using physical and electronic
                        safeguards designed to improve the security of the information we maintain. However, as our
                        Services are hosted electronically, we can make no guarantees as to the security or privacy of
                        your data.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">5. CHILDREN’S PRIVACY</h3>
                        We do not knowingly collect, maintain, or use personal information from children under 18 years
                        of age, and no part of our Services is directed to children. If you are not over 18 years old,
                        then DO NOT DOWNLOAD OR USE THE SERVICES. If you learn that a child has provided us with
                        personal information in violation of this Privacy Policy, then you may alert us at
                        support@answersolution.net
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">6. YOUR CHOICES</h3>
                    </p>
                    <p>
                        <h3 className="policy">Access, Edit and Delete Your Information.</h3>
                        We do not collect your information! All your photos are transferred directly from your phone to
                        the presentation device through the peer-to-peer connection. Albums are stored locally on your
                        device.
                    </p>
                    <p>
                        <h3 className="policy">Device permissions.</h3>
                        Mobile platforms have permission systems for specific types of device data and notifications,
                        such as push notifications and Contacts. Where applicable, you can change your settings on your
                        device to either consent to or oppose the collection of the corresponding information or the
                        display of the corresponding notifications. Of course, if you do that, certain Services may lose
                        full or partial functionality.
                    </p>
                    <p>
                        iOS App needs following permissions:
                        <ul>
                            <li><strong>Photo Library</strong> - PhotoDj: In-Person Slideshow uses access to users photo
                                library to present the photos.
                            </li>
                            <li><strong>Camera</strong> - PhotoDj: In-Person Slideshow uses camera to scan QR code and
                                pair users phone with presentation device (TV/PC/Tablet/Browser).
                            </li>
                            <li><strong>Network</strong> - PhotoDj: In-Person Slideshow uses users local/wifi network to
                                search for a second device and transmit photos to a TV, PC, etc., please allow access to
                                your local network.
                            </li>
                        </ul>
                        Android App needs following permissions:
                        <ul>
                            <li><strong>Photo Library</strong> - PhotoDj: In-Person Slideshow uses access to users photo
                                library to present the photos.
                            </li>
                            <li><strong>Camera</strong> - PhotoDj: In-Person Slideshow uses camera to scan QR code and
                                pair users phone with presentation device (TV/PC/Tablet/Browser).
                            </li>
                        </ul>
                    </p>
                    <p>
                        <h3 className="policy">Uninstall.</h3>
                        You can stop all information collection by the app by not using it, by deactivating/deleting
                        your account from the App’s Account screen, and then uninstalling the app using the standard
                        uninstall process for your device.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">7. INTERNATIONAL USERS</h3>
                        Our Services are hosted in the United States. If you choose to use our Services from outside the
                        United States, with laws governing data collection and use that may differ from United States
                        law, note that you are transferring your personal information outside of those regions to the
                        United States for storage and processing.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">8. DATA RETENTION</h3>
                        Except as provided below, we may retain your personal information for the longer of three (3)
                        years after we become aware that you have ceased using our Services or for so long as we have a
                        legitimate business need for the information or to fulfill any legal and regulatory obligations.
                        We may retain other information that is not personally identifiable for backups, archiving,
                        prevention of fraud and abuse, analytics, or where we otherwise reasonably believe that we have
                        a legitimate reason to do so. We may not know if you have stopped using our Services, so we
                        encourage you to contact us at the appropriate contact email in the How to Contact Us section of
                        this Privacy Policy if you are no longer using the Services.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">9. CONTACTING US</h3>
                        For Customer Support, you may contact us at the Customer Support emails below:
                    </p>
                    <p>
                        support@answersolutions.net
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">10. CHANGES TO OUR PRIVACY POLICY</h3>
                        We reserve the right to change this Privacy Policy at any time. When we update this Privacy
                        Policy, we will notify you of changes that are deemed material under applicable legal
                        requirements by updating the date of this Privacy Policy. In some cases (for example, if we make
                        certain material changes) and when required by applicable law, we may inform you by additional
                        means, such as by sending you an email. In some cases, we may obtain your consent to the changes
                        when applicable law requires.
                    </p>
                </div>
            </div>
            <br/>
            <div className="fast_nav containerNoMargin">
                <ul>
                    <li className="first">
                        <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022-2023 ©
                            AnswerSolutions LLC</a>
                    </li>
                    <li>
                        <a href="/" role="button"
                           onClick={() => _this.props.navigate('Home')}> Home </a>
                    </li>
                    <li>
                        <a href="/#help" role="button"
                           onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                    </li>
                    <li>
                        <a href="/#terms" role="button"
                           onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                    </li>
                    <li>
                        <a href="/#policy" role="button"
                           onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                    </li>
                    <li>
                        <a href="/#about" role="button"
                           onClick={() => _this.props.navigate('About')}> Support </a>
                    </li>
                    <li>
                        <a href="mailto:support@answersolutions.net?subject=PhotoDj App">Contact us</a>
                    </li>
                </ul>
            </div>
            <br/>
        </div>
    }
}

export default PrivacyPolicy;