import React, {Component} from 'react';
import './TermsPolicy.css';

let _this;

class TermsOfUse extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        // setCurrentScreen(analytics, window.location.pathname);
        // logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <div className="top_menu containerNoMargin">
                <ul>
                    <li>
                        <a href="/" role="button"
                           onClick={() => _this.props.navigate('Home')}> Home </a>
                    </li>
                    <li>
                        <a href="/#help" role="button"
                           onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                    </li>
                    <li className="first">
                        <a href="/#terms" role="button"
                           onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                    </li>
                    <li>
                        <a href="/#policy" role="button"
                           onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                    </li>
                    <li>
                        <a href="/#about" role="button"
                           onClick={() => _this.props.navigate('About')}> Support </a>
                    </li>
                    <li>
                        <a href="#mobile" role="button"
                           onClick={() => _this.props.navigate('Mobile')}> Mobile </a>
                    </li>
                </ul>
            </div>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>PhotoDj: In-Person Slideshow - Terms of Use
                        </h2>
                    </p>
                    Last updated: November 22, 2023
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">1. Introduction</h3>
                        Welcome, and thank you for your interest in the PhotoDj: In-Person Slideshow App
                        (“AnswerSolutions LLC,” “we,” or “us”), along with our related websites, applications, mobile
                        applications, and other services provided by us (collectively, the “Service”). These Terms of
                        Service are a legally binding contract between you and AnswerSolutions LLC regarding your use of
                        the Service. These Terms of Service reflect how the applications and services of AnswerSolutions
                        LLC work, the laws that apply to our company, and define our relationship with you as you
                        interact with our services.
                    </p>
                    <p>
                        <h3 className="policy">Service provider</h3>
                        <b>PhotoDj: In-Person Slideshow App</b> is a service provided by AnswerSolutions LLC, a company
                        organized under the laws of the State of New Hampshire, USA, and operating under the laws of the
                        USA.
                    </p>
                    <p></p>
                    <p>
                        <h3 className="policy">PLEASE READ THE FOLLOWING TERMS CAREFULLY</h3>
                        BY CLICKING “I ACCEPT,” “NEXT,” DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE
                        SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS AND, AS A CONDITION OF
                        YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY THEM. IF YOU ARE NOT ELIGIBLE OR DO NOT AGREE
                        TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE SERVICE,
                        AND PHOTODJ’S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY BOTH PARTIES TO BE
                        BOUND BY THESE TERMS.
                    </p>
                    <p>
                        AnswerSolutions LLC reserves the right, at its sole discretion, to change, modify, add, or
                        remove portions of these Terms of Use at any time. It is your responsibility to check these
                        Terms of Use periodically for changes. Your continued use of the Service following the posting
                        of changes signifies your acceptance and agreement to the changes. As long as you comply with
                        these Terms of Use, AnswerSolutions LLC grants you a personal, non-exclusive, non-transferable,
                        limited privilege to enter and use our applications, services, and site.
                    </p>

                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">2. Your Account</h3>
                        When registering for or using the Service, you may be required to provide AnswerSolutions LLC
                        with certain personal information, including your phone number ("Credentials"). AnswerSolutions
                        LLC handles such information with the utmost care and security. However, you, not
                        AnswerSolutions LLC, shall be responsible for maintaining and protecting your Credentials in
                        connection with the Service. If your contact information or other information related to your
                        account changes, you must notify AnswerSolutions LLC promptly and keep such information current.
                        You are solely responsible for any activity using your Credentials, whether or not you
                        authorized that activity. Immediately notify AnswerSolutions LLC of any unauthorized use of your
                        Credentials or if your email, password, or phone number has been compromised. If you discover
                        that someone is using your Credentials without your consent or you discover any other breach of
                        security, you agree to notify AnswerSolutions LLC immediately.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">3. Content</h3>
                        ll user interfaces, visual interfaces, trademarks, logos, and computer code are owned,
                        controlled, or licensed by or to AnswerSolutions LLC and are protected by copyright, trademark
                        laws, and various other intellectual property rights and unfair competition laws.
                    </p>
                    <p>
                        A variety of information, reviews, recommendations, messages, comments, posts, text, graphics,
                        software, photographs, videos, data, and other materials ("Content") may be made available
                        through the Service by AnswerSolutions LLC or its suppliers ("AnswerSolutions LLC-Supplied
                        Content"). While AnswerSolutions LLC strives to keep the Content that it provides through the
                        Service accurate, complete, and up-to-date, AnswerSolutions LLC cannot guarantee, and is not
                        responsible for, the accuracy, completeness, or timeliness of any AnswerSolutions LLC-Supplied
                        Content.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">4. Proprietary Rights</h3>
                        You acknowledge and agree that AnswerSolutions LLC (or AnswerSolutions LLC's licensors) own all
                        legal rights, titles, and interests in and to the Service and AnswerSolutions LLC-Supplied
                        Content, and that the Service and AnswerSolutions LLC-Supplied Content are protected by
                        copyrights, trademarks, patents, or other proprietary rights and laws (whether those rights
                        happen to be registered or not, and wherever in the world those rights may exist).
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">5. License from AnswerSolutions LLC and Restrictions on Use</h3>
                        AnswerSolutions LLC grants you a personal, worldwide, royalty-free, non-assignable, and
                        non-exclusive license to use the Site and Service solely for your personal and commercial
                        purposes as permitted by these Terms.
                    </p>
                    <p>
                        You may not (and you may not permit anyone else to): copy, modify, create a derivative work of,
                        reverse engineer, decompile, or otherwise attempt to extract the source code of the Service or
                        any part thereof, unless this is expressly permitted or required by law, or unless you have been
                        specifically told that you may do so by AnswerSolutions LLC, in writing (e.g., through an
                        open-source software license); attempt to disable or circumvent any security mechanisms used by
                        the Service or any applications running on the Service.
                    </p>
                    <p>
                        You may not engage in any activity that interferes with or disrupts the Service (or the servers
                        and networks which are connected to the Service).
                    </p>
                    <p>
                        You may not access the Service in a manner intended to avoid incurring fees or exceeding usage
                        limits or quotas.
                    </p>
                    <p>
                        You may not access the Service for the purpose of bringing an intellectual property infringement
                        claim against AnswerSolutions LLC or for the purpose of creating a product or service
                        competitive with the Service. You may not use any robot, spider, site search/retrieval
                        application, or other manual or automatic program or device to retrieve, index, "scrape," "data
                        mine", or in any way gather Content from the Service.
                    </p>
                    <p>
                        You agree that you will not upload, record, publish, post, link to, transmit or distribute User
                        Content, or otherwise utilize the Service in a manner that: (i) advocates, promotes, incites,
                        instructs, informs, assists, or otherwise encourages violence or any illegal activities; (ii)
                        infringes or violates the copyright, patent, trademark, service mark, trade name, trade secret,
                        or other intellectual property rights of any third party or AnswerSolutions LLC, or any rights
                        of publicity or privacy of any party; (iii) attempts to mislead others about your identity or
                        the origin of a message or other communication, or impersonates or otherwise misrepresents your
                        affiliation with any other person or entity, or is otherwise materially false, misleading, or
                        inaccurate; (iv) promotes, solicits, or comprises inappropriate, harassing, abusive, profane,
                        hateful, defamatory, libelous, threatening, obscene, indecent, vulgar, pornographic, or
                        otherwise objectionable or unlawful content or activity; (v) is harmful to minors; (vi) utilizes
                        or contains viruses, Trojan horses, worms, time bombs, or any other similar software, data, or
                        programs that may damage, detrimentally interfere with, surreptitiously intercept, or
                        expropriate any system, data, personal information, or property of another; or (vii) violates
                        any law, statute, ordinance, or regulation (including without limitation the laws and
                        regulations governing export control, unfair competition, anti-discrimination, or false
                        advertising).
                    </p>
                    <p>
                        You may not use the Service if you are a person barred from receiving the Service under the laws
                        of the United States or other countries, including the country in which you are resident or from
                        which you use the Service. You affirm that you are over the age of 18, as the Service is not
                        intended for children under 18.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">6. Pricing, Subscription, Auto-Renewal Terms</h3>
                        <strong>Free Trial.</strong> Your can use PhotoDj App and Services for free or with some initial
                        fee, with limitations such as limited number of available predefined albums/presentations.
                        <p>
                            <strong>Subscriptions.</strong> You may purchase a subscription to unlock the premium
                            features.
                            For subscriptions, you will be billed immediately upon submitting your purchase, your
                            subscription will automatically renew at the full rate and you will be charged the full fee.
                        </p>
                        <p>
                            <strong>Automatic Renewal; Recurring Charges.</strong> To the extent permitted by applicable
                            law, you agree that your subscription will automatically renew at the end of each
                            subscription period unless canceled at least twenty-four (24) hours in advance of the
                            expiration of your free trial or paid subscription. You will be charged the stated
                            subscription amount for the subscription period upon completion of your registration and
                            submission of your payment information or, if applicable, at the end of your free trial
                            period if you have not canceled at least 24 hours in advance of the expiration of the trial
                            period. You hereby agree to and authorize automatic and recurring billing, and you agree to
                            pay the charges made to your account in connection therewith. You will be billed on a
                            recurring basis at the then-current applicable subscription price plus any applicable taxes
                            at the start of each new subscription period. Payments for renewal subscriptions are
                            processed using the same billing cycle as your current subscription. Subscription fees may
                            change at any time, to the fullest extent permitted under applicable law. Your subscription,
                            and monthly billing of your account, will continue indefinitely until cancelled by you in
                            accordance with the Cancellation Section of this Agreement.</p>
                        <p>
                            <strong>In-App Purchase.</strong> PhotoDj may allow you to purchase subscriptions within the
                            Apps. When you make in-app purchases, you will be billed by the Third-Party Platforms, not
                            AnswerSolutions LLC. To turn off automatic renewal for subscriptions, access your Device’s
                            account settings (Apple/Google). All billing inquiries should be directed to the respective
                            Third-Party Platform (Apple/Google).</p>
                        <p>
                            <strong>Payment Processing.</strong> When you purchase a subscription - whether through a
                            Third-Party Platform - payment may be processed by third parties who act on our behalf or on
                            behalf of the Third-Party Platform (Apple/Google).

                            If you purchased an App through a Third-Party Platform and have a billing dispute, you must
                            contact the applicable Third-Party Platform (Apple/Google) to seek a remedy.</p>
                        <p>
                            <strong>Refund Policy.</strong> If you have a paid subscription, you will not be entitled to
                            any
                            refund of an unused balance if you discontinue your subscription. There are no refunds on
                            paid
                            subscriptions once the charge has gone through for the billing period. All payments are
                            non-refundable.</p>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">7. Privacy Policies</h3>
                        These Services are provided in accordance with our Privacy Policy, which can be found at <a
                        href="#price" role="button"
                        onClick={() => _this.props.navigate('PrivacyPolicy')}>https://photodj.me/#policy</a>. You
                        agree to the use of your User Content and personal information in accordance with these Terms
                        and AnswerSolutions LLC's (PhotoDj: In-Person Slideshow Service) Privacy Policy.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">8. Modification and Termination of Services</h3>
                        AnswerSolutions LLC is constantly innovating in order to provide the best possible experience
                        for its users. You acknowledge and agree that the form and nature of the Services that
                        AnswerSolutions LLC provides may change from time to time without prior notice to you, subject
                        to the terms in its Privacy Policy. Changes to the form and nature of the Services will be
                        effective with respect to all versions of the Services; examples of changes to the form and
                        nature of the Services include, without limitation, changes to fee and payment policies,
                        security patches, added functionality, automatic updates, and other enhancements. Any new
                        features that may be added to the website or the Services from time to time will be subject to
                        these Terms unless stated otherwise.
                    </p>
                    <p>
                        You may terminate these Terms at any time by canceling your account on the Services. You will
                        not receive any refunds if you cancel your account.
                    </p>
                    <p>
                        You agree that AnswerSolutions LLC, in its sole discretion and for any or no reason, may
                        terminate your account or any part thereof. You agree that any termination of your access to the
                        Services may be without prior notice, and you agree that AnswerSolutions LLC will not be liable
                        to you or any third party for such termination.
                    </p>
                    <p>
                        You are solely responsible for exporting your User Content from the Services prior to
                        termination of your account for any reason, provided that if we terminate your account, we will
                        endeavor to provide you a reasonable opportunity to retrieve your User Content.
                    </p>
                    <p>
                        Upon any termination of the Services or your account, these Terms will also terminate, but all
                        provisions of these Terms which, by their nature, should survive termination, shall survive
                        termination, including, without limitation, ownership provisions, warranty disclaimers, and
                        limitations of liability.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">9. Changes to the Terms</h3>
                        These Terms may be amended or updated from time to time without notice and may have changed
                        since your last visit to the website or use of the Services. It is your responsibility to review
                        these Terms for any changes. By continuing to access or use the Services after revisions become
                        effective, you agree to be bound by the revised Terms. If you do not agree to the new Terms,
                        please stop using the Services. Please visit this page regularly to review these Terms for any
                        changes.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">10. DISCLAIMER OF WARRANTY</h3>
                        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND THAT
                        THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE."
                    </p>
                    <p>
                        AnswerSolutions LLC, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS MAKE NO EXPRESS
                        WARRANTIES AND DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SERVICES, INCLUDING IMPLIED
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WITHOUT
                        LIMITING THE GENERALITY OF THE FOREGOING, AnswerSolutions LLC, ITS SUBSIDIARIES AND AFFILIATES,
                        AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (A) YOUR USE OF THE SERVICES WILL
                        MEET YOUR REQUIREMENTS, (B) YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                        FREE FROM ERROR, AND (C) USAGE DATA PROVIDED THROUGH THE SERVICES WILL BE ACCURATE.
                    </p>
                    <p>
                        NOTHING IN THESE TERMS, INCLUDING SECTIONS 10 AND 11, SHALL EXCLUDE OR LIMIT AnswerSolutions
                        LLC'S WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY
                        APPLICABLE LAW.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">11. LIMITATION OF LIABILITY</h3>
                        SUBJECT TO SECTION 10 ABOVE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT AnswerSolutions LLC, ITS
                        SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT,
                        INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER,
                        CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS OF
                        PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION,
                        ANY LOSS OF DATA SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER
                        INTANGIBLE LOSS. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL
                        PURPOSE OF ANY LIMITED REMEDY.
                    </p>
                    <p>
                        THE LIMITATIONS ON AnswerSolutions LLC'S LIABILITY TO YOU IN THIS SECTION SHALL APPLY WHETHER OR
                        NOT AnswerSolutions LLC HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY
                        SUCH LOSSES ARISING.
                    </p>
                    <p>
                        SOME STATES AND JURISDICTIONS MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                        IN NO EVENT SHALL AnswerSolutions LLC'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND
                        CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE) EXCEED THE
                        AMOUNT THAT YOU HAVE ACTUALLY PAID FOR THE SERVICES IN THE PAST TWELVE MONTHS, OR ONE HUNDRED
                        DOLLARS ($100.00), WHICHEVER IS GREATER.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">12. Indemnification</h3>
                        You agree to hold harmless and indemnify AnswerSolutions LLC and its subsidiaries, affiliates,
                        officers, agents, employees, advertisers, licensors, suppliers, or partners (collectively
                        "AnswerSolutions LLC and Partners") from and against any third party claim arising from or in
                        any way related to your breach of the Terms, your use of the Services, your violation of
                        applicable laws, rules or regulations in connection with the Services, or your User Content,
                        including any liability or expense arising from all claims, losses, damages (actual and
                        consequential), suits, judgments, litigation costs, and attorneys' fees, of every kind and
                        nature.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">13. Third-Party Content</h3>
                        The Services may include references, hyperlinks to other websites, resources, or email content.
                        AnswerSolutions LLC has no control over any websites or resources which are provided by
                        companies or persons other than AnswerSolutions LLC.
                    </p>
                    <p>
                        You acknowledge and agree that AnswerSolutions LLC is not responsible for the availability of
                        any such external sites or resources and does not endorse any advertising, products, or other
                        materials on or available from such websites or resources.
                    </p>
                    <p>
                        You acknowledge and agree that AnswerSolutions LLC is not liable for any loss or damage which
                        may be incurred by you or other users as a result of the availability of those external sites or
                        resources or as a result of any reliance placed by you on the completeness, accuracy or
                        existence of any advertising, products or other materials on, or available from, such web sites
                        or resources.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">14. Third Party Software</h3>
                        The Services may incorporate certain third-party software ("Third Party Software"), which is
                        licensed subject to the terms and conditions of the third party licensing such Third Party
                        Software. Nothing in these Terms limits your rights under, or grants you rights that supersede,
                        the terms and conditions of any applicable license for such Third Party Software.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">15. Feedback and Information</h3>
                        Any feedback you provide at this site shall be deemed to be non-confidential. AnswerSolutions
                        LLC shall be free to use such information on an unrestricted basis.
                    </p>
                    <p>
                        The information contained on this website is subject to change without notice. Copyright © 2023
                        AnswerSolutions LLC.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">16. Miscellaneous</h3>
                        These Terms, together with our Privacy Policy and Pricing Terms, constitute the entire agreement
                        between the parties relating to the Services and all related activities. These Terms shall not
                        be modified except in writing, signed by both parties, or by a new posting of these Terms issued
                        by us. If any part of these Terms is held to be unlawful, void, or unenforceable, that part
                        shall be deemed severed and shall not affect the validity and enforceability of the remaining
                        provisions. The failure of AnswerSolutions LLC to exercise or enforce any right or provision
                        under these Terms shall not constitute a waiver of such right or provision. Any waiver of any
                        right or provision by AnswerSolutions LLC must be in writing and shall only apply to the
                        specific instance identified in such writing. You may not assign these Terms or any rights or
                        licenses granted hereunder, whether voluntarily, by operation of law, or otherwise, without our
                        prior written consent. These Terms and any action related thereto will be governed by the laws
                        of the State of California without regard to its conflict of laws provisions. Except for claims
                        for injunctive relief by either party, any dispute or controversy arising out of, in relation
                        to, or in connection with these Terms or your use of the Services shall be finally settled by
                        binding arbitration in Santa Clara County, California under the then current rules of JAMS
                        (formerly known as Judicial Arbitration & Mediation Services) by one (1) arbitrator appointed in
                        accordance with such rules. Where arbitration is not required by these Terms, the exclusive
                        jurisdiction and venue of any action with respect to the subject matter of these Terms will be
                        the state and federal courts located in Santa Clara, California, and each of the parties hereto
                        waives any objection to jurisdiction and venue in such courts. ANY DISPUTE RESOLUTION PROCEEDING
                        ARISING OUT OF OR RELATED TO THESE TERMS OR THE SALES TRANSACTIONS BETWEEN YOU AND
                        AnswerSolutions LLC, WHETHER IN ARBITRATION OR OTHERWISE, SHALL BE CONDUCTED ONLY ON AN
                        INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION, AND YOU EXPRESSLY
                        AGREE THAT CLASS ACTION AND REPRESENTATIVE ACTION PROCEDURES SHALL NOT BE ASSERTED IN NOR APPLY
                        TO ANY ARBITRATION PURSUANT TO THESE TERMS AND CONDITIONS. YOU ALSO AGREE NOT TO BRING ANY LEGAL
                        ACTION, BASED UPON ANY LEGAL THEORY INCLUDING CONTRACT, TORT, EQUITY, OR OTHERWISE, AGAINST
                        AnswerSolutions LLC THAT IS MORE THAN ONE YEAR AFTER THE DATE OF THE APPLICABLE ORDER.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h3 className="policy">17. Contact Us</h3>
                        If you have any questions about these Terms or if you wish to make any complaint or claim
                        concerning the Services, please get in touch with us at: support@answersolutions.net
                    </p>
                    <p>
                        When submitting a complaint, please provide a brief description of the nature of your complaint
                        and the specific services to which your complaint relates.
                    </p>
                </div>
            </div>
            <br/>
            <div className="fast_nav containerNoMargin">
                <ul>
                    <li className="first">
                        <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022-2023 ©
                            AnswerSolutions LLC</a>
                    </li>
                    <li>
                        <a href="/" role="button"
                           onClick={() => _this.props.navigate('Home')}> Home </a>
                    </li>
                    <li>
                        <a href="/#help" role="button"
                           onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                    </li>
                    <li>
                        <a href="/#terms" role="button"
                           onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                    </li>
                    <li>
                        <a href="/#policy" role="button"
                           onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                    </li>
                    <li>
                        <a href="/#about" role="button"
                           onClick={() => _this.props.navigate('About')}> Support </a>
                    </li>
                    <li>
                        <a href="mailto:support@answersolutions.net?subject=PhotoDj App">Contact us</a>
                    </li>
                </ul>
            </div>
            <br/>
        </div>
    }
}

export default TermsOfUse;