import React, {Component} from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Paper from 'material-ui/Paper';
import './Screen.css';

let _this;

const ProgressBar = ({ value, max }) => {
    return (
        <div className="progress-bar">
            <div className="progress" style={{ width: `${(value / max) * 100}%` }}></div>
        </div>
    );
};
const Overlay = ({ children, show }) => {
    return show ? (
        <div className="overlay">
            {children}
        </div>
    ) : null;
};

class Screen extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {
    }

    render() {
        return (
            <MuiThemeProvider>
                <div className="placeholder">

                    <Paper className="paper_photo" zDepth={2}>
                        <div className="photo_center">
                            {this.props.imageURL ? (
                                <img className="photo" src={this.props.imageURL}
                                     alt="Please, go to the browser settings and enable mixed content for this site! Go to 'Privacy and Security' and allow the insecure content for this site."/>
                            ) : (
                                <h1 className="wait_text">Waiting for a Photo...</h1>
                            )}
                            <div>
                                <Overlay show={this.props.progress > 0}>
                                    <ProgressBar value={this.props.progress} max={this.props.maxValue} />
                                </Overlay>
                            </div>
                            <a className="close_button_shadow"><h2 className="fine">Close</h2></a>
                            <a className="close_button"><h2 className="fine"
                                                            onClick={() => _this.props.onCloseButton()}>Close</h2></a>
                        </div>

                    </Paper>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default Screen;