import React, {Component} from 'react';
import './About.css';

let _this;

const faq = [
    {
        question: 'Q: How do I display my photos on a Smart TV or other devices using PhotoDj?',
        answer: 'A: Simply visit https://photodj.me on your Smart TV or device, select photos in the PhotoDj mobile app, scan the QR code displayed on the web page, and enjoy your photos on the big screen.'
    },
    {
        question: 'Q: Do I need to create an account to use PhotoDj?',
        answer: 'A: No, there is no need for any registration or account creation to use PhotoDj.'
    },
    {
        question: 'Q: Is my privacy protected when using PhotoDj?',
        answer: 'A: Yes, PhotoDj guarantees your privacy. Photos are not sent to a server or cloud and are only displayed as long as you want, with no traces left on the presentation device.'
    },
    {
        question: 'Q: Can I use PhotoDj for professional presentations?',
        answer: 'A: Absolutely, PhotoDj is perfect for professional use such as PechaKucha, team presentations, or displaying visual ideas and designs.'
    },
    {
        question: 'Q: What devices are compatible with PhotoDj?',
        answer: 'A: Any device with a modern Internet browser including Smart TVs, PCs, Macs, Tablets, and phones.'
    },
    {
        question: 'Q: What are the benefits of subscribing to PhotoDj Pro?',
        answer: 'A: With PhotoDj Pro, you get access to unlimited predefined albums which allows you to prepare your presentations in advance and showcase a well-curated photo collection.'
    },
    {
        question: 'Q: How do I set up PhotoDj?',
        answer: 'A: Open PhotoDj on your web platform, select photos from your gallery, press "Show/Present", scan the QR Code, and then swipe through the photos on your phone to display them on the big screen.'
    },
    {
        question: 'Q: Is there a companion app for different platforms?',
        answer: 'A: Yes, PhotoDj has a companion app available for Apple TV and supports Android/Google TV as well.'
    },
    {
        question: 'Q: Does PhotoDj work on both iOS and Android devices?',
        answer: 'A: Yes, the mobile app is available for modern iOS and Android devices.'
    },
    {
        question: 'Q: What kind of Internet connection do I need for PhotoDj?',
        answer: 'A: PhotoDj uses your local WiFi network to communicate between your phone and the presentation device. It is important that both devices are on the same network for it to work properly.'
    },
    {
        question: 'Q: Are my photos uploaded and stored on a cloud or server?',
        answer: 'A: No, your photos are not uploaded to a cloud or server. They remain on your phone and are transmitted directly to the presentation device. PhotoDj uses its cloud service solely to facilitate the pairing of your devices.'
    },
    {
        question: 'Q: What happens if I press the "close" button in PhotoDj?',
        answer: 'A: Pressing the "close" button will make your photo disappear from the presentation device\'s screen, ensuring your control over the display duration.'
    },
    {
        question: 'Q: Can I use PhotoDj on devices without a QR scanner?',
        answer: 'A: No, you will need to scan a QR code from the web page using the PhotoDj mobile app to pair devices and display your photos.'
    },
    {
        question: 'Q: Are there any additional features in the PhotoDj mobile app?',
        answer: 'A: The app\'s primary feature is to display photos from your phone gallery onto a larger screen, with easy setup and no need for registration.'
    },
    {
        question: 'Q: How do I know if my Internet browser is modern enough for PhotoDj?',
        answer: 'A: PhotoDj is compatible with any modern Internet browsers like Chrome, Safari, Opera, and Firefox.'
    }
];

class About extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }

    componentDidMount() {
        // ReactGA.pageview('about');
    }


    render() {
        return <div>
            <p className="container">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="/" role="button"
                               onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li>
                            <a href="/#help" role="button"
                               onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                        </li>
                        <li>
                            <a href="/#terms" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/#policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li className="first">
                            <a href="/#about" role="button"
                               onClick={() => _this.props.navigate('About')}> Support </a>
                        </li>
                        <li>
                            <a href="#mobile" role="button"
                               onClick={() => _this.props.navigate('Mobile')}> Mobile </a>
                        </li>
                    </ul>
                </div>
                <br/>
                <p>
                    <div>
                        <img src="/favicon.png" ALIGN="right" HSPACE="10" VSPACE="10" alt="app icon"/>
                        <p><h2>About PhotoDj: In-Person Slideshow</h2></p>
                    </div>
                </p>
                <p>
                    <b>PhotoDj</b> is the easiest way to display the photos from your phone gallery, on Smart TV,
                    PC, Mac, Tablet, another phone, etc.
                    Without any registration.
                </p>
                <p>
                    Show photos to your friends, family or to any stranger.
                    It does not leave any footage on presentation device or server.
                    Your photo appears on presentation device as long as you want to - press a "close" button and your
                    photo
                    will disappear from the presentation device's screen.
                </p>
                <p>
                    You can show photos on any device that has any modern Internet Browser (Chrome, Safari, Opera,
                    Firefox): e.g. Smart TV, PC, Mac, Tablet, Phone etc.
                </p>
                <p>
                    Mobile app is available for modern iOS and Android devices.
                </p>
                <p>
                    <b>PhotoDj</b> uses your phone's and your presentation device’s Internet connection.
                    It communicates with the <b>PhotoDj</b> server to pair these two devices.
                    To exchange the photos <b>PhotoDj</b> uses your local WiFi network.
                    <p>
                        <span className="crimson_text"> This service will work properly if your devices are in the same local network!</span>
                    </p>
                </p>
                <p>
                    <h2>FAQ And Support</h2>
                    {faq.map((item, index) => (
                        <p>
                            <h3 className="policy">{item.question}</h3>
                            {item.answer}

                        </p>
                    ))}
                </p>
                <p>
                    <h2>Need Help? Have a feedback or suggestion?</h2>
                    Contact us via email at support @ answersolutions . net for any assistance.
                </p>
                <p>
                    <div className="fast_nav container">
                        <h2>Download Mobile app</h2>
                        <ul>
                            <li className="first">
                                <a href="https://itunes.apple.com/us/app/photodj/id1325400635?ls=1&amp;mt=8"
                                   target="_blank"
                                   rel="noopener">
                                    <img src="/app_store.png" scale="0" alt="itunes"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.nes.pdj.photodj"
                                   target="_blank"
                                   rel="noopener">
                                    <img src="/google_play.png" scale="0" alt="google play"/>
                                </a>
                            </li>


                        </ul>
                    </div>
                </p>
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2023 ©
                                AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="/" role="button"
                               onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li>
                            <a href="/#help" role="button"
                               onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                        </li>
                        <li>
                            <a href="/#terms" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/#policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li>
                            <a href="/#about" role="button"
                               onClick={() => _this.props.navigate('About')}> Support </a>
                        </li>
                        <li>
                            <a href="mailto:support@answersolutions.net?subject=PhotoDj App">Contact us</a>
                        </li>
                    </ul>
                </div>
            </p>
        </div>
    }
}

export default About;