import React, {Component} from 'react';

class Webrtc extends Component {
    state = {
        connection: new RTCPeerConnection(),
        dataChannel: null,
        localOffer: '',
        remoteOffer: '',
        localAnswer: '',
        remoteAnswer: '',
        localIceCandidates: [],
        remoteIceCandidate: '',
        messages: [],
        messageToSend: '',
        receivedImageUrl: null,
        receivedChunks: [],
        imageBlob: null,
    };

    componentWillUnmount() {
        // If there is an image URL, release it before the component unmounts
        if (this.state.imageBlob) {
            URL.revokeObjectURL(this.state.imageBlob);
        }
    }

    componentDidMount() {
        const connection = this.state.connection;
        connection.oniceconnectionstatechange = () => {
            console.log('ICE connection state: ', connection.iceConnectionState);
        };
        // Create the data channel and establish its event listeners
        const dataChannel = connection.createDataChannel('dataChannel');
        dataChannel.onerror = (event) => {
            // Handle the incoming message
            console.log('onerror:', event.data);
        };
        dataChannel.onmessage = (event) => {
            if (typeof event.data === 'string') {
                // Handle text message
                console.log('Received message:', event.data);
                this.onMessageReceived(event.data);
            } else {
                dataChannel.binaryType = 'arraybuffer';
                this.handleIncomingChunk(event.data);
            }
        }
        dataChannel.onopen = () => {
            console.log('Data channel opened');
            try {
                const regularMessage = JSON.stringify({ content: 'Hello from the offerer!' });
                dataChannel.send(regularMessage);
                console.log('Message sent from the offerer!');
            } catch (error) {
                console.error('Failed to send message from the offerer:', error);
            }
        }
        dataChannel.onstatechange = () => {
            console.log('Data channel state: ', dataChannel.readyState);
        };
        connection.onicecandidate = (event) => {
            if (event.candidate) {
                this.setState(prevState => ({
                    localIceCandidates: [...prevState.localIceCandidates, event.candidate]
                }));
            }
        };

        this.setState({dataChannel});
    }

    onMessageReceived = (message) => {
        console.log('onMessageReceived=>', message);
        try {
            const test = JSON.parse(message);
            if (test.endOfFile) {
                if (this.state.imageBlob) {
                    URL.revokeObjectURL(this.state.imageBlob);
                }
                // Create a new Blob and update the state with the new object URL
                const fileBlob = new Blob(this.state.receivedChunks, { type: 'image/jpg' });
                this.setState({
                    imageBlob: fileBlob,
                    receivedChunks: []
                });
            } else if (test.content) {
                // Handle other string messages that are not control messages
                console.log('Received string message:', test.content);
                this.setState(prevState => ({messages: [...prevState.messages, test.content]}));
            } else {
                console.log('Received unknown message:', test);
                this.setState(prevState => ({messages: [...prevState.messages, test]}));
            }
        } catch (e) {
            console.error('Failed to parse control message:', e);
        }
    };

    createOffer = async () => {
        try {
            const offer = await this.state.connection.createOffer();
            await this.state.connection.setLocalDescription(offer);
            this.setState({localOffer: JSON.stringify(offer)});
        } catch (error) {
            console.error('Error creating an offer:', error);
        }
    };

    setRemoteOffer = async () => {
        try {
            console.log("Remote Offer SDP:", this.state.remoteOffer); // Log the SDP
            const offerDescription = new RTCSessionDescription(JSON.parse(this.state.remoteOffer));
            await this.state.connection.setRemoteDescription(offerDescription);
            // await this.createAnswer();
        } catch (error) {
            console.error('Error setting remote offer:', error);
        }
    };


    createAnswer = async () => {
        try {
            const answer = await this.state.connection.createAnswer();
            await this.state.connection.setLocalDescription(answer);
            this.setState({localAnswer: JSON.stringify(answer)});
        } catch (error) {
            console.error('Error creating an answer:', error);
        }
    };

    setRemoteAnswer = async () => {
        try {
            const answerDescription = new RTCSessionDescription(JSON.parse(this.state.remoteAnswer));
            await this.state.connection.setRemoteDescription(answerDescription);
        } catch (error) {
            console.error('Error setting remote answer:', error);
        }
    };

    addRemoteIceCandidate = async () => {
        try {
            const candidate = new RTCIceCandidate(JSON.parse(this.state.remoteIceCandidate));
            await this.state.connection.addIceCandidate(candidate);
        } catch (error) {
            console.error('Error adding remote ICE candidate:', error);
        }
    };

    sendMessage = () => {
        const {dataChannel, messageToSend} = this.state;
        console.log('sendMessage:', messageToSend);
        const regularMessage = JSON.stringify({ content: messageToSend });
        dataChannel.send(regularMessage);
        this.setState({messageToSend: ''});
    };

    // onImageReceived = (imageUrl) => {
    //     // Update the state with the received image URL to render it
    //     this.setState({receivedImageUrl: imageUrl});
    // };

    handleIncomingChunk = (chunk) => {
        if (chunk instanceof ArrayBuffer) {
            // Add the chunk to the array of received chunks
            console.log('handleIncomingChunk:', chunk.byteLength);
            this.setState(prevState => ({
                receivedChunks: [...prevState.receivedChunks, chunk]
            }));
        } else if (typeof chunk === 'string') {
            // Check if the string message is a control message (like end-of-file)
            try {
                const message = JSON.parse(chunk);
                if (message.endOfFile) {
                    // Combine all received chunks into a Blob
                    const fileBlob = new Blob(this.state.receivedChunks);
                    this.setState({
                        imageBlob: URL.createObjectURL(fileBlob),
                        receivedChunks: [] // Clear the chunks array for the next transfer
                    });
                } else {
                    // Handle other string messages that are not control messages
                    console.log('Received string message:', chunk);
                }
            } catch (e) {
                console.error('Failed to parse control message:', e);
            }
        }
    };

    render() {
        let imageUrl = null;
        if (this.state.imageBlob instanceof Blob) {
            imageUrl = URL.createObjectURL(this.state.imageBlob);
        }
        const {
            localOffer,
            remoteOffer,
            localAnswer,
            remoteAnswer,
            localIceCandidates,
            remoteIceCandidate,
            messages,
            messageToSend
        } = this.state;

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <h2>WebRTC Manual Signaling Offer</h2>
                <button onClick={this.createOffer}>Create Offer</button>
                <textarea value={localOffer} readOnly rows="5"/>
                <textarea
                    value={remoteOffer}
                    onChange={e => this.setState({remoteOffer: e.target.value})}
                    placeholder="Paste Remote Offer Here"
                    rows="5"
                />
                <button onClick={this.setRemoteOffer}>Set Remote Offer</button>
                <button onClick={this.createAnswer}>Create Answer</button>
                <textarea value={localAnswer} readOnly rows="5"/>
                <textarea
                    value={remoteAnswer}
                    onChange={e => this.setState({remoteAnswer: e.target.value})}
                    placeholder="Paste Remote Answer Here"
                    rows="5"
                />
                <button onClick={this.setRemoteAnswer}>Set Remote Answer</button>
                {localIceCandidates.map((candidate, index) => (
                    <div key={index}>
                        <h4>Local ICE Candidate {index + 1}</h4>
                        <textarea value={JSON.stringify(candidate)} readOnly rows="3"/>
                    </div>
                ))}
                <textarea
                    value={remoteIceCandidate}
                    onChange={e => this.setState({remoteIceCandidate: e.target.value})}
                    placeholder="Paste Remote ICE Candidate Here"
                    rows="3"
                />
                <button onClick={this.addRemoteIceCandidate}>Add Remote ICE Candidate</button>
                <div>
                    <h3>Send a Message</h3>
                    <input
                        type="text"
                        value={messageToSend}
                        onChange={e => this.setState({messageToSend: e.target.value})}
                        placeholder="Type your message here"
                    />
                    <button onClick={this.sendMessage}>Send Message</button>
                    {imageUrl && <img src={imageUrl} alt="Received" />}
                    {messages.map((msg, index) => (
                        <p key={index}>{msg}</p>
                    ))}
                </div>
            </div>
        );
    }
}

export default Webrtc;
