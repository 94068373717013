import React, {Component} from 'react';

class WebrtcAnswer extends Component {
    state = {
        connection: new RTCPeerConnection(),
        dataChannel: null,
        localOffer: '',
        remoteOffer: '',
        localAnswer: '',
        remoteAnswer: '',
        localIceCandidates: [],
        remoteIceCandidate: '',
        messages: [],
        messageToSend: ''
    };

    componentDidMount() {
        const connection = this.state.connection;
        connection.oniceconnectionstatechange = () => {
            console.log('ICE connection state: ', connection.iceConnectionState);
        };

        // Listen for the data channel event to receive the data channel from the offer
        connection.ondatachannel = (event) => {
            const dataChannel = event.channel;
            dataChannel.onstatechange = () => {
                console.log('Data channel state: ', dataChannel.readyState);
            };
            dataChannel.onerror = (event) => {
                // Handle the incoming message
                console.log('onerror:', event);
            };
            dataChannel.onmessage = (event) => {
                // Handle the incoming message
                console.log('Received message:', event.data);
                this.onMessageReceived(event.data);
            };
            dataChannel.onopen = () => {
                console.log('Data channel is open');
                // Now you can send messages as well
                try {
                    const regularMessage = JSON.stringify({ content: 'Hello from the answerer!' });
                    dataChannel.send(regularMessage);
                    console.log('Message sent from the answerer!');
                } catch (error) {
                    console.error('Failed to send message from the answerer:', error);
                }
            };
            dataChannel.onclose = () => console.log('Data channel is closed');
            this.setState({dataChannel});
        };


    }

    onMessageReceived = (message) => {
        console.log('onMessageReceived=>', message);
        const test = JSON.parse(message);
        if (test.content) {
            // Handle other string messages that are not control messages
            console.log('Received string message:', test.content);
            this.setState(prevState => ({messages: [...prevState.messages, test.content]}));
        } else {
            console.log('Received unknown message:', test);
            this.setState(prevState => ({messages: [...prevState.messages, test]}));

        }

    };

    createOffer = async () => {
        try {
            const offer = await this.state.connection.createOffer();
            await this.state.connection.setLocalDescription(offer);
            this.setState({localOffer: JSON.stringify(offer)});
        } catch (error) {
            console.error('Error creating an offer:', error);
        }
    };

    setRemoteOffer = async () => {
        try {
            console.log("Remote Offer SDP:", this.state.remoteOffer); // Log the SDP
            const offerDescription = new RTCSessionDescription(JSON.parse(this.state.remoteOffer));
            await this.state.connection.setRemoteDescription(offerDescription);
            // await this.createAnswer();
        } catch (error) {
            console.error('Error setting remote offer:', error);
        }
    };


    createAnswer = async () => {
        try {
            const answer = await this.state.connection.createAnswer();
            await this.state.connection.setLocalDescription(answer);
            this.setState({localAnswer: JSON.stringify(answer)});
        } catch (error) {
            console.error('Error creating an answer:', error);
        }
    };

    setRemoteAnswer = async () => {
        try {
            const answerDescription = new RTCSessionDescription(JSON.parse(this.state.remoteAnswer));
            await this.state.connection.setRemoteDescription(answerDescription);
        } catch (error) {
            console.error('Error setting remote answer:', error);
        }
    };

    addRemoteIceCandidate = async () => {
        try {
            const candidate = new RTCIceCandidate(JSON.parse(this.state.remoteIceCandidate));
            await this.state.connection.addIceCandidate(candidate);
        } catch (error) {
            console.error('Error adding remote ICE candidate:', error);
        }
    };

    sendMessage = () => {
        const {dataChannel, messageToSend} = this.state;
        console.log('sendMessage:', messageToSend);
        const regularMessage = JSON.stringify({ content: messageToSend });
        dataChannel.send(regularMessage);
        this.setState({messageToSend: ''});
    };

    // Function to handle file selection and sending
    sendFile = async (event) => {
        try {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();

                // Wrap the FileReader in a promise
                const readAsArrayBuffer = (file) => {
                    return new Promise((resolve, reject) => {
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = () => reject(reader.error);
                        reader.readAsArrayBuffer(file);
                    });
                };

                const binaryStr = await readAsArrayBuffer(file);
                if (this.state.dataChannel.readyState === 'open') {
                    this.state.dataChannel.send(binaryStr);
                } else {
                    console.log('Data channel is not open');
                }
            }
        } catch (error) {
            console.error('Error sending file:', error);
        }
    };
    sendFileByChunks = (event) => {
        try {
            const file = event.target.files[0];
            if (file) {
                const CHUNK_SIZE = 16 * 1024; // 16 KB chunks
                const reader = new FileReader();

                reader.onload = (event) => {
                    const data = event.target.result;
                    if (this.state.dataChannel.readyState === 'open') {
                        // Send in chunks
                        for (let i = 0; i < data.byteLength; i += CHUNK_SIZE) {
                            const chunk = data.slice(i, i + CHUNK_SIZE);
                            this.state.dataChannel.send(chunk);
                            console.log('sendFileByChunks:', chunk.byteLength);
                        }
                        // After sending all chunks of the file
                        const endOfFileMessage = JSON.stringify({ endOfFile: true });
                        this.state.dataChannel.send(endOfFileMessage);

                    } else {
                        console.log('Data channel is not open');
                    }
                };

                reader.onerror = (error) => {
                    throw error;
                };
                reader.readAsArrayBuffer(file);
            }
        } catch (error) {
            console.error('Error sending file:', error);
        }
    };


    render() {
        const {
            localOffer,
            remoteOffer,
            localAnswer,
            remoteAnswer,
            localIceCandidates,
            remoteIceCandidate,
            messages,
            messageToSend
        } = this.state;

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <h2>WebRTC Manual Signaling Answer</h2>
                <button onClick={this.createOffer}>Create Offer</button>
                <textarea value={localOffer} readOnly rows="5"/>
                <textarea
                    value={remoteOffer}
                    onChange={e => this.setState({remoteOffer: e.target.value})}
                    placeholder="Paste Remote Offer Here"
                    rows="5"
                />
                <button onClick={this.setRemoteOffer}>Set Remote Offer</button>
                <button onClick={this.createAnswer}>Create Answer</button>
                <textarea value={localAnswer} readOnly rows="5"/>
                <textarea
                    value={remoteAnswer}
                    onChange={e => this.setState({remoteAnswer: e.target.value})}
                    placeholder="Paste Remote Answer Here"
                    rows="5"
                />
                <button onClick={this.setRemoteAnswer}>Set Remote Answer</button>
                {localIceCandidates.map((candidate, index) => (
                    <div key={index}>
                        <h4>Local ICE Candidate {index + 1}</h4>
                        <textarea value={JSON.stringify(candidate)} readOnly rows="3"/>
                    </div>
                ))}
                <textarea
                    value={remoteIceCandidate}
                    onChange={e => this.setState({remoteIceCandidate: e.target.value})}
                    placeholder="Paste Remote ICE Candidate Here"
                    rows="3"
                />
                <button onClick={this.addRemoteIceCandidate}>Add Remote ICE Candidate</button>
                <div>
                    <h3>Send a Message</h3>
                    <input
                        type="text"
                        value={messageToSend}
                        onChange={e => this.setState({messageToSend: e.target.value})}
                        placeholder="Type your message here"
                    />
                    <button onClick={this.sendMessage}>Send Message</button>
                    <input type="file" onChange={this.sendFileByChunks}/>
                    {messages.map((msg, index) => (
                        <p key={index}>{msg}</p>
                    ))}
                </div>
            </div>
        );
    }
}

export default WebrtcAnswer;
