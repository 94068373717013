import React, {Component} from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Paper from 'material-ui/Paper';
import {Layout, Flex, Fixed} from 'react-layout-pane';
import io from 'socket.io-client';
import './App.css';
import Screen from "./Screen";
import SessionQRCode from "./SessionQRCode";

let base64 = require('base-64');
let utf8 = require('utf8');
let config = require('../package.json');
let socket_url = "";
const env = process.env.NODE_ENV;
if (env === 'development') {
    socket_url = "http://127.0.0.1:8080";// + config.events.port;
} else {
    socket_url = config.events.url;
}

let socket_client = undefined;
let _this;

class Mobile extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            appStatus: 'wait',
            imageURL: null,
            connection: new RTCPeerConnection(),
            dataChannel: null,
            localOffer: '',
            remoteAnswer: '',
            localIceCandidates: [],
            remoteIceCandidate: '',
            receivedImageUrl: null,
            receivedChunks: []
        };
        _this = this
    }

    componentWillUnmount() {
        // If there is an image URL, release it before the component unmounts
        if (_this.state.receivedImageUrl) {
            URL.revokeObjectURL(_this.state.receivedImageUrl);
        }
    }

    componentDidMount() {
        // console.log('componentDidMount=>');
        this.startSession()
    }

    startSession() {
        // console.log('startSession=>');
        //1. get session id with qr code
        //2. on handshake send session id to backend by AUTH
        //3. on backend keep dictionary of session id and sockets (on disconnect remove)
        //4. send personal command from backend to frontend
        socket_client = io(socket_url, {
            withCredentials: false
        });
        socket_client.on('connect', function (msg) {
            // console.log('connect=>');
            _this.setState({
                appStatus: 'wait'
            });
        });
        socket_client.on('disconnect', function () {
            // console.log('disconnect=>');
            _this.onCloseButton()
        });
        socket_client.on('ON_AUTH', function (data) {
            // console.log('ON_AUTH=>');
            _this.startRTCServer();
        });
        socket_client.on('RECEIVE_MESSAGE', function (data) {
            //remove prev photo, to see download progress
            _this.setState({
                appStatus: 'ready',
                imageURL: null,
                imageDATA: null,
            });
            // console.log('RECEIVE_MESSAGE=>' + data);
            let _url = _this.getPhotoURL(data);
            console.log(_url);
            _this.setState({
                appStatus: 'ready',
                imageURL: _url,
                imageDATA: null,
            });
        });
        socket_client.on('END_SESSION', function (data) {
            // console.log('END_SESSION=>' + socket_client.id);
            //remove prev photo, to see download progress
            _this.onCloseButton()
        });
        socket_client.on('ON_REMOTE_ANSWER', function (data) {
            // console.log('ON_REMOTE_ANSWER=>' + data);
            let bytes = base64.decode(data);
            let answer_str = utf8.decode(bytes);
            // console.log('ON_REMOTE_ANSWER=>' + answer_str);
            _this.setState({remoteAnswer: answer_str});
            _this.setRemoteAnswer(answer_str);
        });
        socket_client.on('ON_REMOTE_ICE', function (data) {
            // console.log('ON_REMOTE_ICE=>' + data);
            let bytes = base64.decode(data);
            let ice_str = utf8.decode(bytes);
            _this.setState({remoteIceCandidate: ice_str});
            _this.addRemoteIceCandidate(ice_str);
        });

        socket_client.connect()
    }

    startRTCServer = () => {
        const connection = _this.state.connection;
        connection.oniceconnectionstatechange = () => {
            // console.log('ICE connection state: ', connection.iceConnectionState);
            if (connection.iceConnectionState === "disconnected") {
                _this.onCloseButton()
            }
        };
        // Create the data channel and establish its event listeners
        const dataChannel = connection.createDataChannel('dataChannel');
        dataChannel.onerror = (error) => {
            console.error('dataChannel.onerror:', error);
            _this.onCloseButton();
        };
        dataChannel.onmessage = (event) => {
            if (typeof event.data === 'string') {
                // console.log('Received message:', event.data);
                _this.onMessageReceived(event.data);
            } else {
                dataChannel.binaryType = 'arraybuffer';
                _this.handleIncomingChunk(event.data);
            }
        }
        dataChannel.onopen = () => {
            // console.log('Data channel opened');
        }
        dataChannel.onstatechange = () => {
            // console.log('Data channel state: ', dataChannel.readyState);
        };
        connection.onicecandidate = (event) => {
            if (event.candidate) {
                _this.setState(prevState => ({
                    localIceCandidates: [...prevState.localIceCandidates, event.candidate]
                }));
                socket_client.emit('local_candidate', JSON.stringify(event.candidate));
            }
        };

        _this.setState({dataChannel});
        _this.createOffer()
    }

    onMessageReceived = (message) => {
        // console.log('onMessageReceived=>', message);
        try {
            const test = JSON.parse(message);
            if (test.startOfFile) {
                // console.log('startOfFile(fileType)=>:', test.fileType);
                // console.log('startOfFile(chunksCount)=>:', test.chunksCount);
                _this.setState({
                    appStatus: 'loading',
                    fileType: test.fileType,
                    chunksCount: test.chunksCount
                });
            } else if (test.endOfMissingChunks) {
                // console.log('endOfMissingChunks(fileType)=>:', test.fileType);
                // console.log('endOfMissingChunks(fileType)=>:', test.fileType);
                // console.log('endOfMissingChunks(chunksCount)=>:', test.chunksCount);
                // console.log('receivedChunks.length:', this.state.receivedChunks.length);
                const fileBlob = new Blob(this.state.receivedChunks, {type: test.fileType});
                const imageUrl = URL.createObjectURL(fileBlob);
                _this.setState({
                    appStatus: 'ready',
                    imageURL: null,
                    receivedImageUrl: imageUrl,
                    receivedChunks: []
                });
            } else if (test.endOfFile && test.chunksCount > 0) {
                // console.log('endOfFile(fileType)=>:', test.fileType);
                // console.log('endOfFile(chunksCount)=>:', test.chunksCount);
                // console.log('receivedChunks.length:', this.state.receivedChunks.length);
                const missing = test.chunksCount - this.state.receivedChunks.length;
                if (missing > 0) {
                    _this.sendACommand('get_missing_chunks:' + missing);
                    // console.log('receivedChunks.missing:', missing);
                } else {
                    if (this.state.receivedImageUrl) {
                        // console.log('revokeObjectURL.prev');
                        URL.revokeObjectURL(this.state.receivedImageUrl);
                        _this.setState({
                            receivedImageUrl: null
                        });
                    }
                    const fileBlob = new Blob(this.state.receivedChunks, {type: test.fileType});
                    const imageUrl = URL.createObjectURL(fileBlob);
                    _this.setState({
                        appStatus: 'ready',
                        imageURL: null,
                        receivedImageUrl: imageUrl,
                        receivedChunks: []
                    });
                }
                // Create a new Blob and update the state with the new object URL
            } else if (test.content) {
                // Handle other string messages that are not control messages
                this.setState(prevState => ({messages: [...prevState.messages, test.content]}));
            } else if (test.pdj_command) {
                if (test.pdj_command === 'next_image_ready') {
                    _this.sendACommand('get_next_image')
                } else if (test.pdj_command === '') {

                }
            } else {
                console.log('Received unknown message:', test);
                //this.setState(prevState => ({messages: [...prevState.messages, test]}));
            }
        } catch (e) {
            console.error('Failed to parse control message:', e);
            this.onCloseButton();
        }
    };

    sendACommand = (command) => {
        try {
            const {dataChannel} = this.state;
            const regularMessage = JSON.stringify({pdj_command: command});
            dataChannel.send(regularMessage);
            // console.log('Message sent =>', regularMessage);
        } catch (error) {
            console.error('Failed to send message:', error);
            this.onCloseButton();
        }
    }

    createOffer = async () => {
        try {
            const offer = await this.state.connection.createOffer();
            await this.state.connection.setLocalDescription(offer);
            this.setState({localOffer: JSON.stringify(offer)});
            socket_client.emit('local_offer', JSON.stringify(offer));
        } catch (error) {
            console.error('Error creating an offer:', error);
            this.onCloseButton();
        }
    };

    setRemoteAnswer = async (answer) => {
        try {
            const answerDescription = new RTCSessionDescription(JSON.parse(answer));
            await this.state.connection.setRemoteDescription(answerDescription);
        } catch (error) {
            console.error('Error setting remote answer:', error);
            this.onCloseButton();
        }
    };

    addRemoteIceCandidate = async (remote_candidate) => {
        try {
            const candidate = new RTCIceCandidate(JSON.parse(remote_candidate));
            await this.state.connection.addIceCandidate(candidate);
        } catch (error) {
            console.error('Error adding remote ICE candidate:', error);
            this.onCloseButton();
        }
    };

    handleIncomingChunk = (chunk) => {
        if (chunk instanceof ArrayBuffer) {
            // Add the chunk to the array of received chunks
            // console.log('handleIncomingChunk:', chunk.byteLength);
            this.setState(prevState => ({
                receivedChunks: [...prevState.receivedChunks, chunk]
            }));
            // console.log('**receivedChunks.length:', this.state.receivedChunks.length);
        }
    };

    onCloseButton() {
        const {dataChannel, connection, appStatus} = _this.state;
        if (appStatus === 'closing') {
            return
        }
        // console.log('onCloseButton..', "1");
        _this.setState({
            appStatus: 'closing'
        });
        if (_this.state.receivedImageUrl) {
            URL.revokeObjectURL(_this.state.receivedImageUrl);
        }
        if (dataChannel) {
            dataChannel.close();
            // console.log('onCloseButton..', "dataChannel.close()");
        }
        if (connection) {
            connection.close();
            // console.log('onCloseButton..', "connection.close()");
        }
        if (socket_client) {
            socket_client.off('RECEIVE_MESSAGE');
            socket_client.off('ON_AUTH');
            socket_client.off('ON_REMOTE_ANSWER');
            socket_client.off('ON_REMOTE_ICE');
            socket_client.off('END_SESSION');

            socket_client.disconnect();
            socket_client.close();
            socket_client = undefined
            // console.log('onCloseButton..', "socket_client.close()");
        }
        _this.setState({
            // appStatus: 'wait',
            connection: new RTCPeerConnection(),
            imageURL: null,
            imageDATA: null,
            receivedImageUrl: null,
            dataChannel: null,
            localOffer: '',
            localIceCandidates: [],
            remoteAnswer: '',
            remoteIceCandidate: '',
            receivedChunks: [],
        });
        // console.log('onCloseButton..', "setState()");
        _this.startSession()
    }


    selectImage() {
        const im = document.getElementsByClassName('qr_paper');
        if (im) {

        }

    }

    getPhotoURL(data) {
        let r = data.split(":");
        let address = r[0];
        let port = r[1];
        let pid = r[2];
        let bytes = base64.decode(address);
        let str_addr = utf8.decode(bytes);

        return 'http://' + str_addr + ":" + port + "/photo?id=" + pid;
    }

    gotSessionId() {
        // console.log('auth=>');
        socket_client.emit('AUTH', window.sid);
    }

    render() {
        return (
            <div className="App">
                <MuiThemeProvider>
                    {this.state.appStatus === 'wait' || this.state.appStatus === 'closing' ? (
                        <Layout type="column">
                            <Flex>
                                <Layout type="row">
                                    <Flex className="content">
                                        <div className='qr_container'>
                                            <ul>
                                                <li className="centered"><h3>Use PhotoDj app to choose the photos from
                                                    your phone gallery,
                                                    then press 'Present'.</h3></li>
                                                <li className="centered"><h3>Scan this QR code, to see selected photos
                                                    here.</h3>
                                                </li>
                                                <li className="centered"><Paper className="qr_paper" zDepth={3}>
                                                    {this.state.appStatus === 'wait' ? (
                                                        <SessionQRCode gotSessionId={this.gotSessionId}/>
                                                    ) : (<div></div>)}
                                                </Paper>
                                                </li>
                                                <li className="centered">
                                                    <h3 className="warning_text">Make sure that both of your devices are
                                                        on the same WiFi
                                                        network!</h3>
                                                </li>
                                                <li className="centered">
                                                    <a className="refresh_button"><h3 className="fine"
                                                                                      onClick={() => _this.onCloseButton()}>New
                                                        QR Code</h3></a>
                                                </li>
                                            </ul>
                                        </div>

                                    </Flex>
                                </Layout>
                            </Flex>
                        </Layout>
                    ) : this.state.receivedImageUrl ? (
                        <Screen imageURL={this.state.receivedImageUrl} progress={this.state.receivedChunks.length} maxValue={this.state.chunksCount}  onCloseButton={this.onCloseButton}/>
                    ) : this.state.imageURL ? (
                        <Screen imageURL={this.state.imageURL} progress={this.state.receivedChunks.length} maxValue={this.state.chunksCount}  onCloseButton={this.onCloseButton}/>
                    ) : (
                        <Screen imageURL={null} progress={this.state.receivedChunks.length} maxValue={this.state.chunksCount} onCloseButton={this.onCloseButton}/>
                    )}
                </MuiThemeProvider>
            </div>
        );
    }
}

export default Mobile;
