import React, {Component} from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Paper from 'material-ui/Paper';
import {Layout, Flex, Fixed} from 'react-layout-pane';
import io from 'socket.io-client';
import './App.css';
import Slide from "./Slide";

let config = require('../package.json');
let socket_url = "";
const env = process.env.NODE_ENV;
if (env === 'development') {
    socket_url = "http://192.168.0.39:8080";
} else {
    socket_url = config.events.url;
}

let socket;
let _this;

class Room extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            appStatus: 'wait',
            userName: '',
            roomName: '',
            imageURL: null
        };
        _this = this;

    }

    componentDidMount() {
        //1. get session id with qr code
        //2. on handshake send session id to backend by AUTH
        //3. on backend keep dictionary of session id and sockets (on disconnect remove)
        //4. send personal command from backend to frontend
        socket = io(socket_url);
        // let _this = this;
        socket.on('connect', function (msg) {
            console.log('connect=>');

        });
        socket.on('disconnect', function () {
            console.log('disconnect=>');
        });

        socket.on('RECEIVE_MESSAGE', function (data) {
            //remove prev photo, to see download progress
            _this.setState({
                appStatus: 'ready',
                imageURL: null
            });
            console.log('RECEIVE_MESSAGE=>' + socket.id);
            let _url = _this.getPhotoURL(data);
            console.log(_url);
            _this.setState({
                appStatus: 'ready',
                imageURL: _url
            });

        });
        socket.on('END_SESSION', function (data) {
            // console.log('END_SESSION=>' + socket.id);
            //remove prev photo, to see download progress
            _this.setState({
                appStatus: 'wait',
                imageURL: null
            });
            _this.window.sid = ''
        });

        try {
            let params = window.location.hash.split("&");
            let name = params[0].split("=")[1];
            let room = params[1].split("=")[1];

            document.getElementById('inputName').value = name;
            document.getElementById('inputRoom').value = room;
            _this.handleSubmit()
        } catch (e) {

        }

    }

    onCloseButton() {
        _this.setState({
            appStatus: 'wait',
            imageURL: null
        });
    }

    selectImage() {
        var im = document.getElementsByClassName('qr_paper');
        if (im) {
            // im.style.webkitTransform = "scale(2)";
            // im.style.MozTransform = "scale(2)";
            // im.style.msTransform = "scale(2)";
            // im.style.OTransform = "scale(2)";
            // im.style.transform = "scale(2)";

            // im.setAttribute('style', null)

            // alert('test1')
            // im.setAttribute('style','transform:rotate(90deg); -webkit-transform: rotate(90deg)')
            // alert('test2')
        }

    }

    getPhotoURL(data) {
        let path = '/api/slide';
        const env = process.env.NODE_ENV;
        if (env === 'development') {
            path = '/slide';
        }

        let r = data.split(":");
        let pid = r[0];
        let room = r[1];
        // todo determine http or https
        ////http://localhost:8082/slide?sid=600a5367-9158-4cbc-a7f1-c766135b658f&room=kdq7wtih&slide=nesf.jpg
        return path + '?sid=' + window.sid + '&room=' + room + '&slide=' + pid;
    }

    gotSessionId() {
        // console.log('auth=>');
        socket.emit('AUTH', window.sid);
    }

    handleSubmit() {
        let path = '/api/joinshow';
        let path2 = '/api/slide';
        const env = process.env.NODE_ENV;
        if (env === 'development') {
            path = '/joinshow';
            path2 = '/slide';
        }
        //http://localhost:8082/joinshow?un=user&room=boston
        let name = document.getElementById('inputName').value;
        let room = document.getElementById('inputRoom').value;
        fetch(path + '?un=' + name + '&room=' + room)
            .then((resp) => resp.text()) // Transform the data into json
            .then(function (data) {
                //show:sid:b106d2d1-2d2e-4dea-86c4-39df93672da8:1:Sergey:nesf.jpg
                //show:sid:a1703022-440c-4daa-bb6f-7623ca47d169:1:Sergey:nesf.jpg:/Users/sergenes/www/slides/kdqpp60l/
                //alert(data);
                console.log('joinshow=>' + data);
                let array = data.split(":");
                let url = path2 + '?sid=' + array[2] + '&room=' + room + '&slide=' + array[5];
                window.sid = array[2];
                _this.setState({
                    appStatus: 'ready',
                    imageURL: url,
                    userName: name,
                    roomName: room
                });
                _this.gotSessionId();
            });
    }

    render() {
        return (
            <div className="App">
                <MuiThemeProvider>
                    {this.state.appStatus === 'wait' ? (
                        <Layout type="column">
                            <Fixed className="header">
                                <Paper className="qr_paper_top" zDepth={1}>
                                    <div id="container">
                                        <div id="left_long">
                                            <h3>PhotoDj-Remote</h3>
                                        </div>
                                        <div id="middle">
                                        </div>
                                        <div id="right" className="top_menu_container">
                                            <div>
                                                <a href="#about" role="button"
                                                   onClick={() => _this.props.navigate('About')}><h3
                                                    className='top_menu'>About</h3>
                                                </a>
                                            </div>
                                            <div>
                                                <a href="#help" role="button"
                                                   onClick={() => _this.props.navigate('Help')}><h3
                                                    className='top_menu'>Help</h3>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </Fixed>
                            <Flex>
                                <Layout type="row">
                                    <Flex className="content">
                                        <div className='qr_container'>
                                            <ul>
                                                <li className="centered"><h3>Use PhotoDj app to choose the photos/slides
                                                    from
                                                    your phone gallery,
                                                    then press 'Show' and select 'Remotely' option.</h3></li>
                                                <li className="centered"><h3>Then share presentation room ID with
                                                    people you want, and ask them to join the Room.</h3></li>
                                                <li className="centered"><h3>Enter your name and the presentation room
                                                    name.</h3>
                                                </li>
                                                <li className="centered"><Paper className="form_paper" zDepth={3}>
                                                    <form onSubmit={_this.handleSubmit}>
                                                        <br/>
                                                        <label>
                                                            Your Name<br/>
                                                            <input type="text" id="inputName"
                                                                   className="form-control-text"/>
                                                        </label>
                                                        <br/>
                                                        <label>
                                                            Room Name<br/>
                                                            <input type="text" id="inputRoom"
                                                                   className="form-control-text"/>
                                                        </label>
                                                        <br/>
                                                        <br/>
                                                        <input type="submit" value="Join"
                                                               className="form-control-button"/>
                                                        <br/>
                                                    </form>
                                                </Paper>
                                                </li>
                                                <li className="centered">
                                                    <h3 className="warning_text">PhotoDj-Remote sends slides through the
                                                        server, but it doesn't keep them - all photos are gone as soon
                                                        as a presenter ends the show!</h3>
                                                </li>
                                            </ul>
                                        </div>

                                    </Flex>
                                </Layout>
                            </Flex>
                            <div className="fast_nav container">
                                <ul>
                                    <li className="first">
                                        <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2023 © AnswerSolutions LLC</a>
                                    </li>
                                    <li>
                                        <a href="#about" role="button"
                                           onClick={() => _this.props.navigate('About')}>About</a>
                                    </li>
                                    <li>
                                        <a href="#help" role="button"
                                           onClick={() => _this.props.navigate('Help')}>Help</a>
                                    </li>
                                    <li>
                                        <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                            Policy</a>
                                    </li>
                                    <li>
                                        <a href="mailto:support@answersolutions.net?subject=PhotoDj App">Contact us</a>
                                    </li>
                                </ul>
                            </div>
                        </Layout>
                    ) : (
                        <div className='qr_container'>
                            <Slide imageURL={this.state.imageURL} onCloseButton={this.onCloseButton}/>
                        </div>
                    )}
                </MuiThemeProvider>
            </div>
        );
    }
}

export default Room;
