import React, {Component} from 'react';
import './Help.css';

let _this;

class Help extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }

    componentDidMount() {}

    render() {
        return <div>
            <div className="container">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="/" role="button"
                               onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li className="first">
                            <a href="/#help" role="button"
                               onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                        </li>
                        <li>
                            <a href="/#terms" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/#policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li>
                            <a href="/#about" role="button"
                               onClick={() => _this.props.navigate('About')}> Support </a>
                        </li>
                        <li>
                            <a href="#mobile" role="button"
                               onClick={() => _this.props.navigate('Mobile')}> Mobile </a>
                        </li>
                    </ul>
                </div>
                <br/>
                <h2 className="policy">
                    To display your photos on Smart TV or desktop browser
                </h2>
                <p>Open <b>PhotoDj</b> mobile app and select photos that you are going to present.
                </p>
                <img className="phone" src="android1.png" alt="phone"/>
                <p>Visit <b>https://photodj.me</b> in your browser e.g. TV Browser, Chrome, Firefox, Opera, Safari or Edge.
                </p>
                <img className="tv" src="tv_frame2.png" alt="tv1"/>
                <br/>
                <p>Press a <b>"Present"</b> button in the <b>PhotoDj</b> app, on your phone, to scan the QR code.
                </p>
                <p>Right away after scanning the QR code you will see a first selected photo on big screen.</p>
                <br/><img className="tv" src="tv_frame1.png" alt="tv2"/>
                <p>Use swipe <b>right/left</b> gesture to present a next/previous photo.
                </p>
                <p>Press <b>"Close"</b> on iPhone or <b>"Back"</b> button on Android to end the presentation.
                </p>
                <br/><br/><br/>

                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2023 © AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="/" role="button"
                               onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li>
                            <a href="/#help" role="button"
                               onClick={() => _this.props.navigate('Help')}> Tutorial </a>
                        </li>
                        <li>
                            <a href="/#terms" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/#policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li>
                            <a href="/#about" role="button"
                               onClick={() => _this.props.navigate('About')}> Support </a>
                        </li>
                        <li>
                            <a href="mailto:support@answersolutions.net?subject=PhotoDj App">Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

export default Help;