import React, {Component} from 'react';

import Home from "./Home";
import About from "./About";
import Help from "./Help";
import Policy from "./Policy"
import Mobile from "./Mobile"
import Room from "./Room"
import Webrtc from "./Webrtc";
import WebrtcAnswer from "./WebrtcAnswer"

import PrivacyPolicy from "./PrivacyPolicy"
import TermsOfUse from "./TermsOfUse"
import Pricing from "./Pricing";
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-111277469-1');

let _this;

class PhotoDjFrontApp extends Component {
    constructor(props, context) {
        super(props, context);

        let init_screen = 'Home';
        // console.log('PhotoDjFrontApp.constructor=>' + window.location.hash);
        if (window.location.hash.startsWith('#terms')){
            init_screen = 'TermsOfUse';
        } else if (window.location.hash.startsWith('#policy')){
            init_screen = 'PrivacyPolicy';
        } else if (window.location.hash === '#about') {
            init_screen = 'About';
        } else if (window.location.hash === '#help') {
            init_screen = 'Help';
        } else if (window.location.hash === '#pricing') {
            init_screen = 'Pricing';
        } else if (window.location.hash === '#mobile') {
            init_screen = 'Mobile';
        // } else if (window.location.hash.startsWith('#room')) {
        //     init_screen = 'Room';
        // } else if (window.location.hash.startsWith('#rtc')) {
        //     init_screen = 'Webrtc';
        // } else if (window.location.hash.startsWith('#answer')) {
        //     init_screen = 'WebrtcAnswer';
        } else if (window.location.pathname.endsWith('photodj-privacy-policy')) {
            init_screen = 'PrivacyPolicy';
        } else if (window.location.pathname.endsWith('photodj-terms-of-use') ){
            init_screen = 'TermsOfUse';
        }

        this.state = {
            target: init_screen
        };
        _this = this;

    }

    componentDidMount() {
        // console.log('componentDidMount=>');
    }

    navigate(new_target) {
        // console.log('navigate=>' + new_target);
        _this.setState({
            target: new_target,
        });
    };

    render() {

        let screen = null;
        // if (this.state.target === 'WebrtcAnswer') {
        //     screen = <WebrtcAnswer navigate={this.navigate}/>
        // } else if (this.state.target === 'Webrtc') {
        //     screen = <Webrtc navigate={this.navigate}/>
        // } else
        if (this.state.target === 'PrivacyPolicy') {
            screen = <PrivacyPolicy navigate={this.navigate}/>
        } else if (this.state.target === 'TermsOfUse') {
            screen = <TermsOfUse navigate={this.navigate}/>
        } else if (this.state.target === 'About') {
            screen = <About navigate={this.navigate}/>
        } else if (this.state.target === 'Help') {
            screen = <Help navigate={this.navigate}/>
        // } else if (this.state.target === 'Pricing') {
        //     screen = <Pricing navigate={this.navigate}/>
        } else if (this.state.target === 'Mobile') {
            screen = <Mobile navigate={this.navigate}/>
        // } else if (this.state.target === 'Room') {
        //     screen = <Room navigate={this.navigate}/>
        } else {
            screen = <Home navigate={this.navigate}/>
        }

        return (
            <div className="App">
                {screen}
            </div>
        );
    }
}

export default PhotoDjFrontApp;
